import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { filePreviewService } from '../../../apis/fileService';
import { FileSession } from '../../../models/FileSession';
import { ActionType, ProcessorType, getFileExtension, isDocumentProcessorSupported } from '../../../utilities/SupportedFileFormats';
import FileManagerDocumentPreviewer from '../DocumentEditors/FileManagerDocumentPreviewer';
import FileManagerPDFPreviewer from '../DocumentEditors/FileManagerPDFPreviewer';
import FileManagerRichTextEditorPreview from '../DocumentEditors/FileManagerRichTextEditorPreview';
import FileManagerSpreadsheetPreviewer from '../DocumentEditors/FileManagerSpreadsheetPreviewer';
import ToastNotification from '../Notifications/ToastNotification';
import FileManagerOperations from '../Services/FileManagerOperations';

interface Props {
    FileSession: FileSession;
    FileManagerOperations: FileManagerOperations;
}

export default function FileManagerPreviewer({ FileSession, FileManagerOperations }: Props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fileId = queryParams.get('uuid') ?? "";
    const [currentFileId, setCurrentFileId] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [fileExt, setFileExt] = useState<string>();
    const [base64String, setBase64String] = useState<string>();


    useEffect(() => {
        async function fetchSelectedFileContent() {
            FileManagerOperations.showToastNotification(`Loading file data. Please wait...`, "Loading file", "Information");
            const result = await filePreviewService(fileId, FileSession);
            var data = result?.data?.Data;
            if (data) {

                try {
                    if (data.FileName) {
                        setBase64String(data.FileBase64String);
                        setFileName(data.FileName);
                        setCurrentFileId(data.Id);

                        let extension = getFileExtension(data.FileName);
                        if (extension)
                            setFileExt(extension);
                    }

                } catch (e) {
                    console.log(e);
                }
            }
        }
        fetchSelectedFileContent().then(() => { });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ backgroundColor: '#3f3f3f' }}>
            {
                base64String && fileName ?


                    <div style={{ backgroundColor: '#3f3f3f' }} >
                        <div className="sidebar-child" >

                            <div id="card-preview" className="e-card"
                                style={{
                                    backgroundColor: '#3f3f3f',
                                    height: '99vh',
                                    visibility: `${(fileName) ? `visible` : `hidden`}`
                                }}>
                                {
                                    isDocumentProcessorSupported(fileName, ProcessorType.Image, ActionType.Preview) &&

                                    <img
                                        src={
                                            fileExt?.toLowerCase() === ".svg"
                                                ? `data:image/svg+xml;utf8,${encodeURIComponent(atob(base64String))}`
                                                : `data:image/${fileExt?.toLowerCase().substring(1)};base64,${base64String}`
                                        }
                                        alt={fileName}
                                        style={{
                                            height: 'auto',
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            overflowY: 'auto',
                                        }}
                                    />
                                }


                                {
                                    (fileName
                                        && isDocumentProcessorSupported(fileName, ProcessorType.PDF, ActionType.Preview) && currentFileId
                                    ) &&


                                    <FileManagerPDFPreviewer pdfPath={encodeURIComponent(currentFileId)}
                                        fileId={encodeURIComponent(currentFileId)}
                                        fileName={encodeURIComponent(fileName)}
                                        fileSession={FileSession}
                                        FileManagerOperations={FileManagerOperations}
                                    />
                                }


                                {
                                    (fileName
                                        && isDocumentProcessorSupported(fileName, ProcessorType.Text, ActionType.Preview)) &&

                                    <div style={{
                                        'height': '95vh',
                                        'width': '100%',
                                        'overflowY': 'auto',
                                    }} >

                                        <FileManagerRichTextEditorPreview
                                            html={base64String} />
                                    </div>
                                }

                                {
                                    (fileName
                                        && isDocumentProcessorSupported(fileName, ProcessorType.Excel, ActionType.Preview)) &&


                                    <FileManagerSpreadsheetPreviewer
                                        base64FileString={base64String}
                                        fileName={fileName}
                                        fileSession={FileSession} />
                                }

                                {
                                    (fileName
                                        && isDocumentProcessorSupported(fileName, ProcessorType.Word, ActionType.Preview)) &&

                                    <div style={{
                                        width: '100%',
                                        overflowY: 'hidden'
                                    }} >
                                        <FileManagerDocumentPreviewer
                                            filePath={currentFileId ?? ""}
                                            fileName={fileName}
                                            refresh={() => { }}
                                            fileSession={FileSession}
                                        />
                                    </div>

                                }
                            </div>
                        </div>
                    </div>

                    :

                    <div>Loading please wait...</div>

            }
            <ToastNotification />
        </div>
    )
}
