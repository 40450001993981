import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { FileSession } from '../../../models/FileSession';
import FileManagerOperations from '../Services/FileManagerOperations';
import FilePreview from './FilePreview';
import FileProperties from './FileProperties';


interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}
export default function FileManagerPropertiesPane({ FileManagerOperations, FileSession }: Props) {

    return (
        <div className="properties-preview-pane" style={{ height: 'calc(90vh - 25px)' } }>
            <TabComponent>
                <TabItemsDirective>
                    <TabItemDirective header={{ text: 'Properties' }} content={() => <FileProperties FileManagerOperations={FileManagerOperations} FileSession={FileSession}  IsExternalView={false}/>} />
                    <TabItemDirective header={{ text: 'Preview' }} content={() => <FilePreview FileManagerOperations={FileManagerOperations} FileSession={FileSession} IsExternalView={false} />}  />
                </TabItemsDirective>
            </TabComponent>
        </div>
    );
}
