/* eslint-disable @typescript-eslint/no-unused-vars */
import { getAccessToken } from "../utilities/getTokenAndExpireTime";
declare global {
    interface Window {
        _env_: {
            API_URL: string;
            ODOO_HOST: string;
            SYNCFUSION_LICENSE: string;
            SYNCFUSION_PDF_SERVICE_URL: string;
            B2C_SIGNUP_SIGNIN: string;
            B2C_FORGOT_PASSWORD: string;
            B2C_EDIT_PROFILE: string;
            B2C_LOGOUT_REDIRECT_URL: string;
            B2C_AUTHORITY_DOMAIN: string;
            B2C_TENANT: string;
            B2C_CLIENTID: string;
        };
    }
}

const odooHost = window._env_.ODOO_HOST;
const apiUrl = window._env_.API_URL;
const syncfusionLicense = window._env_.SYNCFUSION_LICENSE;
const syncfusionPDFServiceUrl = window._env_.SYNCFUSION_PDF_SERVICE_URL;

//const odooHost = "http://localhost:3000";
//const apiUrl = "http://localhost:5000";

export const ODOO_URL = odooHost;

const getServerURL = () => {
    return `${apiUrl}/`;
}

const getSyncfusionLicense = () => {
    return syncfusionLicense;
}

const getSyncfusionPDFServiceUrl = () => {
    return syncfusionPDFServiceUrl;
}

const getFileServiceURL = () => {
    return `${apiUrl}/`;
}

const getPDFServiceURL = () => {
    return `${apiUrl}/api/pdfviewer`;
}

const getSpreadsheetServiceURL = () => {
    return `${apiUrl}/api/spreadsheetviewer/Open`;
}

const getSpreadsheetSaveServiceURL = () => {
    return `${apiUrl}/api/spreadsheetviewer/Save`;
}

const getStandalonePDFServiceURL = () => {
    return `https://cdn.syncfusion.com/ej2/25.1.35/dist/ej2-pdfviewer-lib`;
}

const getB2cSignupSignin = () => {
    return window._env_.B2C_SIGNUP_SIGNIN;
}

const getB2cForgotPassword = () => {
    return window._env_.B2C_FORGOT_PASSWORD;
}

const getB2cEditProfile = () => {
    return window._env_.B2C_EDIT_PROFILE;
}

const getB2cLogoutRedirectUrl = () => {
    return window._env_.B2C_LOGOUT_REDIRECT_URL;
}

const getB2cAuthorityDomain = () => {
    return window._env_.B2C_AUTHORITY_DOMAIN;
}

const getB2cTenant = () => {
    return window._env_.B2C_TENANT;
}

const getB2cClientId = () => {
    return window._env_.B2C_CLIENTID;
}

export const b2cSignupSignin = getB2cSignupSignin();
export const b2cForgotPassword = getB2cForgotPassword();
export const b2cEditProfile = getB2cEditProfile();
export const b2cLogoutRedirectUrl = getB2cLogoutRedirectUrl();
export const b2cAuthorityDomain = getB2cAuthorityDomain();
export const b2cTenant = getB2cTenant();
export const b2cClientId = getB2cClientId();


export const SERVER_URL = getServerURL();
export const SERVICE_URL = getFileServiceURL();
export const PDF_SERVICE_URL = getPDFServiceURL();
export const SPREADSHEET_SERVICE_URL = getSpreadsheetServiceURL();
export const SYNCFUSION_LICENSE = getSyncfusionLicense();
export const STANDALONE_PDF_SERVICE_URL = getStandalonePDFServiceURL();
export const SPREADSHEET_SERVICE_URL_SAVE = getSpreadsheetSaveServiceURL();

export const REDIRECT_URL = window.location.href.includes("?") ? window.location.href.split("?")[0] : window.location.href;

export const getStoreSetupURL = () => {
    const domainArr = window.location.href.split("/");
    return `${domainArr[0]}//${domainArr[2]}/settings/store-setup`;
}

export const tokenCookieName = 'sv_tkn';
export const refreshTokenKey = 'refreshToken';
export const accesstokenSessionKey = 'accesstoken';
export const accesstokenSessionExpiryKey = 'expireAt';

export const getToken = () => {
    try {
        const token = getAccessToken(tokenCookieName);
        return token;
    } catch (err) {
        return "";
    }
}

export const config = { headers: { Authorization: `Bearer ${getToken()}` } };
// Use getAxiosConfig instead of config object as it will give you a stale config if the token in cookie/session storage changes
export const getAxiosConfig = () => ({ headers: { Authorization: `Bearer ${getToken()}` } });

export const APP_COLOR = {
    appLightBlue: "#3c8dbc",
    appHrGrey: "#e9ecef",
    deleteRed: "#D30101"
};
