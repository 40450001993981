import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-react-popups';

interface Props {
    size: "sxx" | "sx" | "sm" | "md" | "lg";
    setOpen: (value: boolean) => void;
    saveChanges: (value: boolean) => void;
    visible: boolean;
    children: any;
    showSaveButton?: boolean;
    canResize?: boolean;
    allowDragging?: boolean;
    isSaving?: boolean;
    target: string;
    title?: string;
    resizeChildren?: () => void;
    buttons?: ButtonPropsModel[]
}

export default function FileManagerStyledDialog({ visible, setOpen, children, saveChanges, resizeChildren, title, target, isSaving, size, buttons, canResize, showSaveButton, allowDragging }: Props) {

    var position = { X: 'center', Y: 'center' };
    const handleClose = () => {
        setOpen(false);
    };

    const getHeightSize = (size: any) => {

        if (size === "sxx") {
            return "15vh";
        }

        if (size === "sx") {
            return "30vh";
        }

        if (size === "sm") {
            return "30vh";
        }

        if (size === "md") {
            return "50vh";
        }

        if (size === "lg") {
            return "85vh";
        }
        return "85vh";
    }

    const getWidthSize = (size: any) => {
        if (size === "sxx") {
            return "20vw";
        }

        if (size === "sx") {
            return "20vw";
        }

        if (size === "sm") {
            return "40vw";
        }


        if (size === "md") {
            return "60vw";
        }

        if (size === "lg") {
            return "80vw";
        }

        return "80vh";
    }



    //Executes on Resize Stop to resize the pdfviewer
    const onResizeStop = () => {
        if (resizeChildren) {
            resizeChildren();
        }
    }

    return (
        <div className="e-card" style={{ display: visible ? 'block' : 'none' }}>
            <DialogComponent
                id={`dialog-${Math.random().toString(36).slice(2, 7)}`}
                height={`${getHeightSize(size)}`}
                width={`${getWidthSize(size)}`}
                target={target}
                position={position}
                enableResize={canResize}
                showCloseIcon={true}
                visible={visible}
                close={handleClose}
                isModal={true}
                minHeight="200px"
                allowDragging={allowDragging}
                header={title}
                resizeStop={onResizeStop}
                buttons={buttons || [{}]}
            >
                <div>
                    {
                        children
                    }
                </div>

            </DialogComponent>
        </div>
    )
}
