import { PaneDirective, PanesDirective, ResizingEventArgs, SplitterComponent } from '@syncfusion/ej2-react-layouts'
import { useLocation } from 'react-router-dom'
import { FileSession } from '../../../models/FileSession'
import '../BaseComponents/FileManagerLayout.scss'
import FileManagerDialogs from '../Dialogs/FileManagerDialogs'
import FileManagerOperations from '../Services/FileManagerOperations'
import FileManagerDataGrid from './FileManagerDataGrid'
import FileManagerNavigationMenu from './FileManagerNavigationMenu'
import FileManagerPropertiesPane from './FileManagerPropertiesPane'
import FileManagerTreeView from './FileManagerTreeView'
import { useEffect, useRef, useState } from 'react'

interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}
export default function FileManagerLayout({ FileManagerOperations, FileSession }: Props) {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const splitterRef = useRef<SplitterComponent>(null);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const displayVersion = queryParams.get('display') ?? "";
    const attachedModel = queryParams.get('UniqueSourceId') ?? "";

    FileManagerOperations.setDisplayVersion(displayVersion);
    FileManagerOperations.setAttachedModel(attachedModel);

    const handleResizing = (e: ResizingEventArgs) => {
        console.log('Resizing...' + e.paneSize);
        FileManagerOperations.setResizeToken();
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth < 768; 
            setIsSmallScreen(isSmall);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const treeViewContent = () => <FileManagerTreeView FileManagerOperations={FileManagerOperations} FileSession={FileSession} />;
    const dataGridContent = () => <FileManagerDataGrid FileManagerOperations={FileManagerOperations} FileSession={FileSession} />;
    const propertiesPaneContent = () => <FileManagerPropertiesPane FileManagerOperations={FileManagerOperations} FileSession={FileSession} />;

    return (
        <div className="file-manager-layout" id="content-app" onDragOver={(e) => e.preventDefault()}>
            {/* Top Menu */}
            <FileManagerNavigationMenu FileManagerOperations={FileManagerOperations} FileSession={FileSession} />

            {/* File Manager Content */}
            <SplitterComponent
                height="90vh"
                width="100%"
                ref={splitterRef}
                orientation={isSmallScreen ? "Vertical" : "Horizontal"}
                separatorSize={4}
                resizing={handleResizing}
            >
                <PanesDirective>
                    {/* TreeView Pane */}
                    <PaneDirective size={isSmallScreen ? "30%" : "20%"} min={isSmallScreen ? "20%" : "15%"} content={treeViewContent} />

                    {/* Data Grid Pane */}
                    <PaneDirective size={isSmallScreen ? "70%" : "55%"} min={isSmallScreen ? "50%" : "50%"} content={dataGridContent} />

                    {/* Properties/Preview Pane */}
                    {!isSmallScreen && (
                        <PaneDirective collapsible={true} size="25%" min="2px" content={propertiesPaneContent} />
                    )}
                </PanesDirective>
            </SplitterComponent>

            {/* Dialog Types */}
            <FileManagerDialogs FileManagerOperations={FileManagerOperations} FileSession={FileSession} />
        </div>
    );
}