import React, { useRef } from 'react'
import { FilePageItem } from '../../../../models/FilePageItem';
import { FileSession } from '../../../../models/FileSession';

interface Props {
    displayedItems: FilePageItem[];
    setSelectedIndex: (index: number) => void;
    zoomValue: number;
    mainThumbnailsRef: React.MutableRefObject<(HTMLDivElement | null)[]>;
}

export default function MainPageContent({ displayedItems, setSelectedIndex, zoomValue, mainThumbnailsRef }: Props) {
    return (
        <div
            id="editor-content"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'inherit',
            }}
        >
            <div
                style={{
                    height: '100%',
                    width: '100vw',
                    margin: '0 auto',
                    overflowY: 'auto',
                }}
            >
                {displayedItems.map((filePage) => (
                    <div
                        key={filePage.Id.toString()}
                        ref={(el) => (mainThumbnailsRef.current[filePage.PageNumber] = el!)}
                        style={{
                            margin: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            setSelectedIndex(filePage.PageNumber - 1);
                        }}
                    >
                        <img
                            id={`img-${Math.random().toString(36).slice(2, 7)}`}
                            src={filePage.ImageData}
                            alt={filePage.PageNumber.toString()}
                            style={{
                                objectFit: 'contain',
                                width: `${zoomValue}%`,
                                height: '100%',
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}