import { Grid } from '@mui/material';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useEffect, useRef, useState } from 'react';
import { FileSession } from '../../../models/FileSession';
import FileManagerOperations from '../Services/FileManagerOperations';
import FileManagerBreadcrumb from './FileManagerBreadcrumb';
import FileUploader, { FileUploaderRef } from './FileUploader';
interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}

export default function FileManagerNavigationMenu({ FileManagerOperations, FileSession }: Props) {
    const [menuType, setMenuType] = useState<string>('default');
    const fileUploaderRef = useRef<FileUploaderRef>(null);
    const [displayVersion, setDisplayVersion] = useState<string>('');
    const [highlightedItem, setHighlightedItem] = useState<string>('');
    const [clipboardLength, setClipboardLength] = useState<number>(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Function to determine the menu type based on selection
    const updateMenuType = () => {
        const selectedGridViewItems = FileManagerOperations.selectedGridViewItems;
        const selectedFileItem = FileManagerOperations.selectedFileItem;
        setDisplayVersion(FileManagerOperations.displayVersion);

        if (selectedGridViewItems?.length < 1) {
            setMenuType('gridFolder');
            return;
        }
        if (selectedFileItem) {
            if (selectedFileItem.isFile) {
                if (selectedGridViewItems.length > 1) {
                    setMenuType('multipleSelection');
                    return;
                }
                else {
                    setMenuType('file');
                    return;
                }
            }
            else {
                if (selectedGridViewItems.length > 1) {
                    setMenuType('multipleSelection');
                    return;
                }
                else {
                    setMenuType('gridFolder');
                    return;
                }
            }
        }
        else {
            if (selectedGridViewItems.length > 1) {
                setMenuType('multipleSelection');
                return;
            }
            else {
                setMenuType('gridFolder');
                return;
            }
        }
    };

    const updateDisplayVersion = () => {

        setDisplayVersion(FileManagerOperations.displayVersion);
        return;
    };

    const updateHighlightedItem = () => {
        if (FileManagerOperations.highlightedItem) {
            setHighlightedItem(FileManagerOperations.highlightedItem.name);

        }
    }

    const updateClipboardLenth = () => {
        setClipboardLength(FileManagerOperations?.clipboard?.length);
    }

    useEffect(() => {
        // Subscribe to selection change events in FileManagerOperations
        FileManagerOperations.on('treeViewSelectionChanged', updateMenuType);
        FileManagerOperations.on('gridViewSelectionChanged', updateMenuType);
        FileManagerOperations.on('fileItemSelected', updateMenuType);
        FileManagerOperations.on('displayVersionUpdated', updateDisplayVersion);
        FileManagerOperations.on('highlightedItemChanged', updateHighlightedItem);
        FileManagerOperations.on('clipboardUpdated', updateClipboardLenth);
        //highlightedItemChanged

        // Clean up event listeners when component unmounts
        return () => {
            FileManagerOperations.off('treeViewSelectionChanged', updateMenuType);
            FileManagerOperations.off('gridViewSelectionChanged', updateMenuType);
            FileManagerOperations.off('fileItemSelected', updateMenuType);
            FileManagerOperations.off('displayVersionUpdated', updateDisplayVersion);
            FileManagerOperations.off('highlightedItemChanged', updateHighlightedItem);
            FileManagerOperations.off('clipboardUpdated', updateClipboardLenth);
        };
    }, [FileManagerOperations]);

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth < 768;
            setIsSmallScreen(isSmall);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handleCreateFolder = () => {
        FileManagerOperations.setSelectDialog('createFolder');
        FileManagerOperations.setDialogOpen();
        //const folderName = prompt("Enter the new folder name:");
        //if (folderName) {
        //    FileManagerOperations.createFolder(folderName);
        //}
    };

    const handleUpload = async () => {
        fileUploaderRef.current?.triggerFileDialog();
    };

    const handleDelete = () => {
        FileManagerOperations.deleteSelectedItems();
    };

    const handleRename = () => {
        FileManagerOperations.setSelectDialog('rename');
        FileManagerOperations.setDialogOpen();
    };

    const handleCut = () => {
        FileManagerOperations.cutItems();
    };

    const handleCopy = () => {
        FileManagerOperations.copyItems();
    };

    const handlePaste = () => {
        FileManagerOperations.pasteItems();
    };

    const handleDownload = () => {
        FileManagerOperations.downloadSelectedItems();
    };

    const handleReIndex = async () => {
        await FileManagerOperations.reIndexAllFiles();
    }

    const handleRefresh = async () => {
        let selectedFileItem = FileManagerOperations?.selectedFileItem ?? FileManagerOperations.highlightedItem;
       
        if (selectedFileItem) {
            FileManagerOperations.refreshFilesByParentId(selectedFileItem.id);
        }
        else {
            FileManagerOperations.refreshFilesByParentId("0");
        }
    };

    const handleAttachments = async () => {
        FileManagerOperations.getAttachedItems();
        FileManagerOperations.setSelectDialog('attachments');
        FileManagerOperations.setDialogOpen();
    };

    const isActionDisabled = () => {
        return false;
        let selectedFileItem = FileManagerOperations?.selectedFileItem;
        if (selectedFileItem) {
            return false;
        }
        else {
            return true;
        }
    }

    const renderButton = (iconCss: string, onClick: () => void, text: string, disabled: boolean = false) => {
        return (
            <ButtonComponent
                className="default-button-style"
                iconCss={iconCss}
                onClick={onClick}
                disabled={disabled}
                title={text}
            >
                {!isSmallScreen && text} {/* Show text only if not on a small screen */}
            </ButtonComponent>
        );
    };

    return (
        <Grid container spacing={2} style={{ marginLeft: '0px', width: 'calc( 100vw - 10px)' }}>
            <Grid item xs={3} >
                <FileManagerBreadcrumb FileManagerOperations={FileManagerOperations} FileSession={FileSession} />

            </Grid>
            <Grid item xs={8}>
                <div className="file-manager-menu">
                    {/* Menu for TreeView folder or default */}
                    {(menuType === 'default' || menuType === 'treeviewFolder') && (
                        <>
                            {renderButton('fa fa-plus', handleCreateFolder, 'Create Folder', isActionDisabled())}
                            {renderButton('fa fa-upload', handleUpload, 'Upload', isActionDisabled())}
                            <FileUploader FileManagerOperations={FileManagerOperations} FileSession={FileSession} ref={fileUploaderRef} />
                            {renderButton('fa fa-refresh', handleRefresh, 'Refresh', isActionDisabled())}
                            {renderButton('fa fa-retweet', handleReIndex, 'Reindex', isActionDisabled())}
                        </>
                    )}

                    {/* Menu for GridView folder */}
                    {menuType === 'gridFolder' && (
                        <>
                            {renderButton('fa fa-plus', handleCreateFolder, 'Create Folder', isActionDisabled())}
                            {renderButton('fa fa-upload', handleUpload, 'Upload', isActionDisabled())}
                            <FileUploader FileManagerOperations={FileManagerOperations} FileSession={FileSession} ref={fileUploaderRef} />
                            {renderButton('fa fa-trash-o', handleDelete, 'Delete', isActionDisabled())}
                            {renderButton('fa fa-edit', handleRename, 'Rename', isActionDisabled())}
                            {renderButton('fa fa-files-o', handleCopy, 'Copy', isActionDisabled())}
                            {renderButton('fa fa-scissors', handleCut, 'Cut', isActionDisabled())}
                            {renderButton('fa fa-clipboard', handlePaste, 'Paste', clipboardLength === 0)}
                            {renderButton('fa fa-download', handleDownload, 'Download', isActionDisabled())}
                            {renderButton('fa fa-refresh', handleRefresh, 'Refresh', isActionDisabled())}
                            {renderButton('fa fa-retweet', handleReIndex, 'Reindex', isActionDisabled())}
                            {displayVersion === "select" &&
                                renderButton('fa fa-paperclip', handleAttachments, 'Attachments', isActionDisabled())}
                        </>
                    )}

                    {/* Menu for File */}
                    {menuType === 'file' && (
                        <>
                            {renderButton('fa fa-trash-o', handleDelete, 'Delete', isActionDisabled())}
                            {renderButton('fa fa-edit', handleRename, 'Rename', isActionDisabled())}
                            {renderButton('fa fa-files-o', handleCopy, 'Copy', isActionDisabled())}
                            {renderButton('fa fa-scissors', handleCut, 'Cut', isActionDisabled())}
                            {renderButton('fa fa-download', handleDownload, 'Download', isActionDisabled())}
                            {renderButton('fa fa-refresh', handleRefresh, 'Refresh', isActionDisabled())}
                            {renderButton('fa fa-retweet', handleReIndex, 'Reindex', isActionDisabled())}
                            {displayVersion === "select" &&
                                renderButton('fa fa-paperclip', handleAttachments, 'Attachments', isActionDisabled())}
                        </>
                    )}

                    {/* Menu for Multiple Selection */}
                    {menuType === 'multipleSelection' && (
                        <>
                            {renderButton('fa fa-trash-o', handleDelete, 'Delete', isActionDisabled())}
                            {renderButton('fa fa-files-o', handleCopy, 'Copy', isActionDisabled())}
                            {renderButton('fa fa-scissors', handleCut, 'Cut', isActionDisabled())}
                            {renderButton('fa fa-download', handleDownload, 'Download', isActionDisabled())}
                            {renderButton('fa fa-refresh', handleRefresh, 'Refresh', isActionDisabled())}
                            {renderButton('fa fa-retweet', handleReIndex, 'Reindex', isActionDisabled())}
                            {displayVersion === "select" &&
                                renderButton('fa fa-paperclip', handleAttachments, 'Attachments', isActionDisabled())}
                        </>
                    )}

                    {/* Fallback for undefined menuType */}
                    {menuType === undefined && (
                        <p className="info-message">No valid menu available. Please select an item.</p>
                    )}
                </div>
            </Grid>
            <Grid item xs={1}>

            </Grid>
        </Grid>


    );
}