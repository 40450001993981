import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import React, { useEffect, useState } from 'react';
import { FileDetailsObject } from '../../../models/FileDetails';
import { FileItem } from '../../../models/FileItem';
import { FileSession } from '../../../models/FileSession';
import { getFormattedDate } from '../../../utilities/Formatters';
import { getFileExtension } from '../../../utilities/SupportedFileFormats';
import { ShowToast } from '../Notifications/ShowToast';
import ToastNotification from '../Notifications/ToastNotification';
import FileManagerOperations from '../Services/FileManagerOperations';
import FileManagerMergePdfFiles from './FileManagerMergePdfFiles';
import FileManagerStyledDialog from './FileManagerStyledDialog';
interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}

export default function FileManagerDialogs({ FileManagerOperations, FileSession }: Props) {

    const [selectedDialog, setSelectedDialog] = React.useState<string>('');
    const [dialogVisibility, setDialogVisibility] = React.useState<boolean>(false);
    const [selectedTreeViewItem, setSelectedTreeViewItem] = React.useState<FileDetailsObject | null>(null);
    const [selectedGridViewItems, setSelectedGridViewItems] = React.useState<FileDetailsObject[]>([]);
    const [newFolderName, setNewFolderName] = React.useState<string>('');
    const [temporaryName, setTemporaryName] = React.useState<string>('');
    const [fileDetailsObject, setFileDetailsObject] = useState<FileDetailsObject | null>();
    const attachedItemFields: object = { text: 'name', value: 'id' };
    const [attachedItems, setAttachedItems] = useState<FileDetailsObject[]>([]);

    useEffect(() => {

        const handleDialogVisibility = () => {
            setDialogVisibility(FileManagerOperations.dialogVisible);
        };

        const handleSelectedDialog = () => {
            setSelectedDialog(FileManagerOperations.selectedDialog);
        };

        const handleFetchAttachedItems = () => {
            setAttachedItems(FileManagerOperations.attachedItems as any[]);
        };

        const handleFileDetailsObject = () => {
            setFileDetailsObject(FileManagerOperations.selectedFileItem);
            setTemporaryName(FileManagerOperations.selectedFileItem?.name ?? '');
        };

        // Subscribe to selection change events in FileManagerOperations
        FileManagerOperations.on('dialogOpen', handleDialogVisibility);
        FileManagerOperations.on('dialogClose', handleDialogVisibility);
        FileManagerOperations.on('selectedDialogChanged', handleSelectedDialog);
        FileManagerOperations.on('fileItemSelected', handleFileDetailsObject);
        FileManagerOperations.on('attachedItemsUpdated', handleFetchAttachedItems);

        // Clean up event listeners when component unmounts
        return () => {
            FileManagerOperations.off('dialogOpen', handleDialogVisibility);
            FileManagerOperations.off('dialogClose', handleSelectedDialog);
            FileManagerOperations.off('selectedDialogChanged', handleSelectedDialog);
            FileManagerOperations.off('fileItemSelected', handleFileDetailsObject);
            FileManagerOperations.off('attachedItemsUpdated', handleFetchAttachedItems);
        };
    }, [FileManagerOperations]);

    const mapFileDetailsToFileItems = (detailsArray: FileDetailsObject[]): FileItem[] => {
        return detailsArray.map(detail => new FileItem(
            detail.id?.toString(), // Map 'id' to string or undefined
            detail.name,           // Map 'name' directly
            undefined,             // base64String not present in FileDetailsObject
            detail.path,           // Map 'path' directly
            0,             // 'order' not present in FileDetailsObject
            detail.jsonProperties,             // childJson not present in FileDetailsObject
            detail.jsonProperties,             // parentJson not present in FileDetailsObject
            detail.fileProviderId  // Map 'fileProviderId' directly
        ));
    };

    // Convert selectedGridViewItems to FileItem array
    const fileItems = mapFileDetailsToFileItems(FileManagerOperations.selectedGridViewItems);



    const handleNewFolderNameChange = (e: any) => {
        setNewFolderName(e.target.value);
    }

    const handleTemporaryNameChange = (e: any) => {
        setTemporaryName(e.target.value);
    }

    const handleCreateNewFolder = () => {

        // Regex for valid folder names: Allows alphanumeric, spaces, hyphens, underscores, and periods
        const folderNameRegex = /^[a-zA-Z0-9 _.-]+$/;

        if (!newFolderName) {
            FileManagerOperations.showToastNotification( 'Folder name cannot be empty!','Create Folder','Error');
            return;
        }

        if (!folderNameRegex.test(newFolderName)) {
            FileManagerOperations.showToastNotification('Invalid folder name. Please use only alphanumeric characters, spaces, underscores, hyphens, and periods!', 'Create Folder','Error');
            return;
        }

        FileManagerOperations.showToastNotification(`Creating new folder: ${newFolderName} in ${fileDetailsObject?.name}`,'Create Folder', 'Information');
        // Call API or logic to create the folder
        FileManagerOperations.createFolder(newFolderName)
            .then(() => {
                FileManagerOperations.showToastNotification( `Folder: ${newFolderName} created successfully`,'Create Folder', 'Success');
                setNewFolderName(''); // Clear input after success
            })
            .catch((error) => {
                console.error('Error creating folder:', error);
                FileManagerOperations.showToastNotification(`Error creating folder : ${newFolderName}`, 'Create Folder', 'Error');
            });
        FileManagerOperations.setDialogClose();
    };

    const getDisplayTitle = () => {
        if (FileManagerOperations.selectedFileItem) {

            if (!FileManagerOperations.selectedFileItem.isFile && !FileManagerOperations.selectedFileItem.isRoot) {
                return "Folder";
            }
            if (!FileManagerOperations.selectedFileItem.isFile && FileManagerOperations.selectedFileItem.isRoot) {
                return "Root";
            }
        }

        return "File";
    }

    const handleFileRename = () => {

        if (fileDetailsObject?.isFile) {
            let fileRegex = /^[^<>:"/\\|?*]+\.[^<>:"/\\|?*]+$/;
            if (!fileRegex.test(temporaryName.toLowerCase())) {
                FileManagerOperations.showToastNotification( `Invalid filename : ${newFolderName}`,'File Rename', 'Error');
                return;
            }
        }
        else {
            let folderRegex = /^(?!.*\.[^.]+$)(?!.*[. ]$)(?!(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$)[^<>:"/\\|?*]+$/i;
            if (!folderRegex.test(temporaryName.toLowerCase())) {
                FileManagerOperations.showToastNotification(`Invalid filename : ${newFolderName}`,'File Rename', 'Error');
                return;
            }
        }

        ShowToast({
            header: 'File Rename',
            message: `Renaming File. Please wait...`,
            type: 'Information',
        });
        if (fileDetailsObject?.isFile) {
            var fileExt = getFileExtension(fileDetailsObject?.name);
            var newFileExt = getFileExtension(temporaryName);
            if (newFileExt === fileExt && fileDetailsObject) {
                let currentPath = fileDetailsObject?.id ?? (fileDetailsObject.filterPath + fileDetailsObject.name);
                if (currentPath) {
                    FileManagerOperations.renameItem(temporaryName);
                }
            }
            else {
                //no change
                if (newFileExt === temporaryName) {
                    let currentPath = fileDetailsObject?.id ?? (fileDetailsObject.filterPath + fileDetailsObject.name);
                    if (currentPath) {
                        FileManagerOperations.renameItem(`${temporaryName}.${fileExt}`);
                    }
                }
                else {
                    let currentPath = fileDetailsObject?.id ?? (fileDetailsObject.filterPath + fileDetailsObject.name);
                    if (currentPath) {
                        FileManagerOperations.renameItem(temporaryName);
                    }
                }
            }
        }
        else {
            FileManagerOperations.renameItem(temporaryName);
        }

        FileManagerOperations.setDialogClose();
    }

    const removeAllAttachement = async () => {
        await FileManagerOperations.removeAllAttachement();
    }

    const removeSelectedAttachements = async () => {
        //await FileManagerOperations.removeSelectedAttachements();
    }

    return (
        <div>
            
            {/*CREATE DIALOG*/}
            {dialogVisibility && selectedDialog === 'createFolder' && (
                < FileManagerStyledDialog visible={dialogVisibility}
                    size={"sxx"}
                    target='#content-app'
                    isSaving={false}
                    canResize={false}
                    title={"Create Folder"}
                    showSaveButton={false}
                    setOpen={
                        function (value: boolean): void {
                            FileManagerOperations.setDialogClose();
                        }
                    }

                    saveChanges={
                        async function (value: boolean): Promise<void> {

                        }
                    }

                    buttons={
                        [
                            {
                                buttonModel: { content: 'Cancel', iconCss: 'e-icons e-close-dialog', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                                click: () => FileManagerOperations.setDialogClose()
                            },
                            {
                                buttonModel: { content: 'Save', iconCss: 'e-icons e-ok-check-mark', cssClass: 'e-flat toolbar-button', isPrimary: true },
                                click: () => handleCreateNewFolder()
                            }
                        ]}

                >
                    <>
                        <div style={{ padding: '5px', marginRight: '10px' }} >
                            <input
                                className="e-input"
                                type="text"
                                value={newFolderName}
                                placeholder="Folder Name"
                                onChange={handleNewFolderNameChange}
                            />
                        </div>

                    </>

                </FileManagerStyledDialog >
            )}


            {dialogVisibility && selectedDialog === 'rename' && (
                < FileManagerStyledDialog visible={dialogVisibility}
                    size={"sxx"}
                    target='#content-app'
                    isSaving={false}
                    canResize={false}
                    allowDragging={false}
                    title={"Rename"}
                    showSaveButton={false}

                    setOpen={
                        function (value: boolean): void {
                            FileManagerOperations.setDialogClose();
                        }
                    }

                    saveChanges={
                        async function (value: boolean): Promise<void> {

                        }
                    }

                    buttons={
                        [
                            {
                                buttonModel: { content: 'Cancel', iconCss: 'e-icons e-close-dialog', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                                click: () => FileManagerOperations.setDialogClose()
                            },
                            {
                                buttonModel: { content: 'Save', iconCss: 'e-icons e-ok-check-mark', cssClass: 'e-flat toolbar-button', isPrimary: true },
                                click: () => handleFileRename()
                            }
                        ]}
                >
                    <>
                        <div style={{ padding: '5px', marginRight: '10px' }} >
                            <input
                                className="e-input"
                                type="text"
                                value={temporaryName}
                                placeholder="New File Name"
                                onChange={handleTemporaryNameChange}
                            />
                        </div>

                    </>

                </FileManagerStyledDialog >
            )}

            {dialogVisibility && selectedDialog === 'attachments' && (
                < FileManagerStyledDialog visible={dialogVisibility}
                    size={"md"}
                    target='#content-app'
                    isSaving={false}
                    title={"Attached Files"}
                    allowDragging={false}
                    showSaveButton={false}
                    canResize={true}
                    setOpen={function (value: boolean): void {
                        FileManagerOperations.setDialogClose();
                    }}

                    saveChanges={async function (value: boolean): Promise<void> {

                    }}

                    buttons={[
                        {
                            buttonModel: { content: 'Cancel', iconCss: 'e-icons e-close-dialog', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                            click: () => FileManagerOperations.setDialogClose()
                        },
                        {
                            buttonModel: { content: 'Remove All', iconCss: 'e-icons e-delete', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                            click: () => removeAllAttachement()
                        },
                        {
                            buttonModel: { content: 'Remove Selected', iconCss: 'e-icons e-delete', cssClass: 'e-flat toolbar-button', isPrimary: true },
                            click: () => removeSelectedAttachements()
                        }
                    ]}
                >
                    <>
                        {
                            <>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <ListViewComponent id="list" dataSource={attachedItems as any} fields={attachedItemFields} showCheckBox={true} checkBoxPosition="Right" />
                                </div>
                            </>
                        }
                    </>

                </FileManagerStyledDialog >
            )}


            {dialogVisibility && selectedDialog === 'merge' && (
                < FileManagerStyledDialog visible={dialogVisibility}
                    size={"sm"}
                    target='#content-app'
                    isSaving={false}
                    title={"Merge PDF Files"}
                    allowDragging={false}
                    showSaveButton={false}
                    canResize={true}
                    setOpen={
                        function (value: boolean): void {
                            FileManagerOperations.setDialogClose();
                        }
                    }

                    saveChanges={
                        async function (value: boolean): Promise<void> {

                        }
                    }

                >
                    <>
                        {
                            <FileManagerMergePdfFiles

                                files={fileItems}
                                setOpen={function (value: boolean): void {
                                    if (FileManagerOperations?.selectedFileItem) {
                                        FileManagerOperations.refreshFiles(FileManagerOperations.selectedFileItem);
                                    }
                                    FileManagerOperations.setDialogClose();
                                }}
                                fileProvider={FileManagerOperations.selectedFileItem?.fileProviderId ?? ""} fileSession={FileSession} />
                        }
                    </>

                </FileManagerStyledDialog >
            )}


            {dialogVisibility && selectedDialog === 'details' && (
                < FileManagerStyledDialog visible={dialogVisibility}
                    size={"sx"}
                    target='#content-app'
                    isSaving={false}
                    title={getDisplayTitle() + ' Details'}
                    allowDragging={false}
                    canResize={false}
                    showSaveButton={false}
                    setOpen={
                        function (value: boolean): void {
                            FileManagerOperations.setDialogClose();

                        }
                    }

                    saveChanges={
                        async function (value: boolean): Promise<void> {

                        }
                    }

                    buttons={
                        [
                            {
                                buttonModel: { content: 'Ok', iconCss: 'e-icons e-ok-check-mark', cssClass: 'e-flat toolbar-button', isPrimary: true },
                                click: () => FileManagerOperations.setDialogClose()
                            }
                        ]}

                >
                    <>
                        {
                            FileManagerOperations.selectedFileItem?.isRoot === true
                                ?
                                <div style={{ padding: '5px', marginRight: '10px' }} >

                                    <div style={{ padding: '5px', marginRight: '10px' }} >
                                        <div className={"fileDetailItem"}>
                                            Display Name: {FileManagerOperations.selectedFileItem?.name}
                                        </div>
                                        <div className={"fileDetailItem"}>
                                            Root Type: {FileManagerOperations.selectedFileItem?.fileProviderType}
                                        </div>
                                        <div className={"fileDetailItem"}>
                                            Root Name: {FileManagerOperations.selectedFileItem?.fileProviderName}
                                        </div>
                                    </div>

                                </div>

                                :
                                <div style={{ padding: '5px', marginRight: '10px' }} >

                                    <div style={{ padding: '5px', marginRight: '10px' }} >
                                        {
                                            FileManagerOperations.selectedFileItem?.isFile === true
                                                ?
                                                <div className={"fileDetailItem"}>
                                                    File Name: {FileManagerOperations.selectedFileItem?.name}
                                                </div>
                                                :
                                                <div className={"fileDetailItem"}>
                                                    Folder Name: {FileManagerOperations.selectedFileItem?.name}
                                                </div>
                                        }

                                        <div className={"fileDetailItem"}>
                                            Date Created: {getFormattedDate(FileManagerOperations.selectedFileItem?.dateCreated)}
                                        </div>
                                        <div className={"fileDetailItem"}>
                                            Date Modified: {getFormattedDate(FileManagerOperations.selectedFileItem?.dateModified)}
                                        </div>

                                    </div>

                                </div>

                        }


                    </>

                </FileManagerStyledDialog >
            )}
            <ToastNotification />  <ToastNotification />
        </div>
    )
}
