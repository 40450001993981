 import React, { useEffect, useState } from 'react'
import FileManagerOperations from '../Services/FileManagerOperations';
import { FileSession } from '../../../models/FileSession';
import ToastNotification from '../Notifications/ToastNotification';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import FileProperties from '../BaseComponents/FileProperties';
import FilePreview from '../BaseComponents/FilePreview';
import { useLocation } from 'react-router-dom';
import { filePreviewService } from '../../../apis/fileService';
import { getFileExtension } from '../../../utilities/SupportedFileFormats';
import { FileDetailsObject } from '../../../models/FileDetails';

interface Props {
    FileSession: FileSession;
    FileManagerOperations: FileManagerOperations;
}

export default function FileManagerPropertiesWindow({ FileSession, FileManagerOperations }: Props) {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fileId = queryParams.get('uuid') ?? "";
    const [currentFileId, setCurrentFileId] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [fileExt, setFileExt] = useState<string>();
    const [base64String, setBase64String] = useState<string>();


    useEffect(() => {
        async function fetchSelectedFileContent() {
            FileManagerOperations.showToastNotification(`Loading file data. Please wait...`, "Loading file", "Information");


            
            const result = await filePreviewService(fileId, FileSession);
            var data = result?.data?.Data;
            if (data) {

                try {
                    if (data.FileName) {
                        setBase64String(data.FileBase64String);
                        setFileName(data.FileName);
                        setCurrentFileId(data.Id);

                        let currentFileDetailsObject = data.FolderItem as unknown as FileDetailsObject;
                        debugger
                        let extension = getFileExtension(data.FileName);
                        if (extension)
                            setFileExt(extension);

                        FileManagerOperations.setSelectedFileItem(currentFileDetailsObject);
                    }

                } catch (e) {
                    console.log(e);
                }
            }
        }
        fetchSelectedFileContent().then(() => { });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //useEffect(() => {

    //    const handleSelectedFileItem = () => {
    //        const selectedItem = FileManagerOperations.selectedFileItem;
    //        setFileDetailsObject(selectedItem);
    //    };
    //    const handleitemClassResponseUpdate = () => {
    //        if (FileManagerOperations.itemClassResponse) {
    //            setFolderItemClassResponse(FileManagerOperations.itemClassResponse);
    //        }
    //    };

    //    // Subscribe to selection change events in FileManagerOperations
    //    FileManagerOperations.on('itemClassResponseUpdated', handleitemClassResponseUpdate);
    //    FileManagerOperations.on('fileItemSelected', handleSelectedFileItem);
    //    // Clean up event listeners when component unmounts
    //    return () => {
    //        FileManagerOperations.off('itemClassResponseUpdated', handleitemClassResponseUpdate);
    //        FileManagerOperations.off('fileItemSelected', handleSelectedFileItem);
    //    };
    //}, [FileManagerOperations]);

   return (
       <div style={{ height: 'calc(100vh - 25px)' }}>
           <TabComponent>
               <TabItemsDirective>
                   <TabItemDirective header={{ text: 'Properties' }} content={() => <FileProperties FileManagerOperations={FileManagerOperations} FileSession={FileSession} IsExternalView={true} />} />
                   <TabItemDirective header={{ text: 'Preview' }} content={() => <FilePreview FileManagerOperations={FileManagerOperations} FileSession={FileSession} IsExternalView={true} />} />
               </TabItemsDirective>
           </TabComponent>
           <ToastNotification />
       </div>
   )
 }
 