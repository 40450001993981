
import { LogLevel } from '@azure/msal-browser';
import { b2cAuthorityDomain, b2cClientId, b2cEditProfile, b2cForgotPassword, b2cLogoutRedirectUrl, b2cSignupSignin, b2cTenant } from './environment/env_dev';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: b2cSignupSignin,        // e.g., "B2C_1_SignUpSignIn"
        forgotPassword: b2cForgotPassword,    // e.g., "B2C_1_ForgotPassword"
        editProfile: b2cEditProfile,          // e.g., "B2C_1_EditProfile"
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${b2cAuthorityDomain}/${b2cTenant}/${b2cSignupSignin}`,
        },
        forgotPassword: {
            authority: `https://${b2cAuthorityDomain}/${b2cTenant}/${b2cForgotPassword}`,
        },
        editProfile: {
            authority: `https://${b2cAuthorityDomain}/${b2cTenant}/${b2cEditProfile}`,
        },
    },
    authorityDomain: b2cAuthorityDomain,

};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: b2cClientId,                    // Application ID from Azure AD B2C
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Default to SUSI authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Trusted B2C tenant domain
        redirectUri: '/',                        // Adjust as needed for your app
        postLogoutRedirectUri: b2cLogoutRedirectUrl, // URL after logout
        navigateToLoginRequestUrl: false,        // Keep as false per original config
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },


    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [],
};


/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};