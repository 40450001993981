import { PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fileOpenService } from '../../../apis/fileService';
import { STANDALONE_PDF_SERVICE_URL } from '../../../environment/env_dev';
import { FileSession } from '../../../models/FileSession';
import ToastNotification from '../Notifications/ToastNotification';
import FileManagerOperations from '../Services/FileManagerOperations';

interface Props {
    fileSession: FileSession;
    pdfPath: string;
    fileName: string;
    fileId: string;
    isMaximized?: boolean;
    FileManagerOperations: FileManagerOperations;
}
export default function FileManagerPDFPreviewer({ fileSession, pdfPath, fileName, fileId, isMaximized, FileManagerOperations }: Props) {
    //const pdfjs = require('pdfjs-dist');
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    //const pdfPath = queryParams.get('path') ?? "";
    //const fileName = queryParams.get('name') ?? "";
    //const fileId = queryParams.get('name') ?? "";
    //const fileProvider = queryParams.get('provider') ?? "";

    const [base64FileString, setBase64FileString] = useState<string>();
    const [parentWidth, setParentWidth] = useState<string>();
    const [checked, setChecked] = React.useState<number[]>();
    const [gridHeight, setGridHeight] = React.useState<number>(200);
    const [sliderValue, setSliderValue] = React.useState<number>(0);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [hoveredPageNumber, setHoveredPageNumber] = React.useState<number>(0);
    const [pdfPageCount, setPdfPageCount] = React.useState<number>(0);
    const [fileDeleteDialogVisibility, setFileDeleteDialogVisibility] = useState<boolean>(false);
    const [isDocumentBusy, setIsDocumentBusy] = useState<boolean>(false);

    const pdfViewer = useRef<PdfViewerComponent>(null);

    useEffect(() => {
        const handleRefresh = () => {
            if (pdfViewer && pdfViewer.current) {
                var element = document.getElementById('#preview-pane');
                var width = element?.clientWidth;
                setParentWidth(width?.toString() ?? '100%');
                // pdfViewer.current.width = width?.toString()+"px" ?? '100%';
                pdfViewer.current.updateViewerContainer();

            }
        }

        FileManagerOperations.on('resizeTokenChanged', handleRefresh);

        return () => {
            FileManagerOperations.off('resizeTokenChanged', handleRefresh);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FileManagerOperations]);


    useEffect(() => {
        async function loadFileFromServer() {
            try {
                // make a fetch request to the action method URL
                const result = await fileOpenService(pdfPath, fileName,  "", fileSession);
                var data = result?.data?.Data;
                if (data) {
                    setBase64FileString(data.FileBase64String);
                }
            } catch (error) {
                // handle any errors that may occur
                console.log('There has been a problem with your fetch operation: ', error);
            }

        }

        loadFileFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileId]);


    return (
        <div id="pdf-side-previewer" style={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 70px)`, width: `${parentWidth}px` }}>
            {
                base64FileString &&
                <PdfViewerComponent
                    id={`containerPdfViewerComponent_${fileId}`}
                    ref={pdfViewer}
                    documentPath={base64FileString}
                    resourceUrl={STANDALONE_PDF_SERVICE_URL}
                    width={`${parentWidth}px`}
                    height={`100%`}
                >
                </PdfViewerComponent>
            }
            <ToastNotification />
        </div >

    )
};