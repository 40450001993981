import React from 'react'
import { Grid } from '@mui/material';
import FileManagerOperations from '../../Services/FileManagerOperations';
import { FilePageItem } from '../../../../models/FilePageItem';

interface Props {
    isMediumOrLarger: boolean;
    thumbnailElements: JSX.Element[];
    displayedItems: FilePageItem[];
    sideThumbnailsRef: React.MutableRefObject<(HTMLDivElement | null)[]>;
}

export default function NavigationThumbnail({ isMediumOrLarger, thumbnailElements, displayedItems, sideThumbnailsRef }: Props) {
    return (
        <div>
            {/* Conditional rendering based on screen size */}
            {isMediumOrLarger && (
                <div>
                    <div className="page-previews">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: '6px',
                                justifyContent: 'center',
                            }}
                        ></div>
                        <div
                            style={{
                                padding: '10px',
                                height: 'auto',
                                minWidth: '130px',
                            }}
                        >
                            <Grid
                                container
                                direction={{ xs: 'column', md: 'row' }}
                                spacing={5}
                                justifyContent="center"
                                alignItems="center"
                                style={{ marginTop: '3px', height: '82vh' }}
                            >
                                {displayedItems && <>{thumbnailElements}</>}
                            </Grid>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}